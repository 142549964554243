import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
  DatePicker
} from "antd";
import ConfectionService from "../../../../services/confection.service";
import ProduitService from "../../../../services/produit.service";
import dayjs from "dayjs";
import { Collapse } from 'antd';
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const AnalyseStockConfection = () => {
  const [searchForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [produits, setProduits] = useState([]);

  useEffect(() => {
    loadProducts()
    prodCongfectionsHistory(null);
    return () => {
      clearStates();
    };
  }, []);

  const loadProducts = async () => {
    setLoading(true);
    const response = await ProduitService.getProduitsForDashboard();
    if (response.status === 200 || 204) setProduits(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  }

  const prodCongfectionsHistory = async (data) => {
    setLoading(true);
    // pass the form
    const body = data ? data : null 
    const response = await ConfectionService.filterProdHistory(body);
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const initTableData = (data) => {
    setDataTable(data);
  };

  const clearStates = () => {
    setLoading(false);
  };

  const cancelSearch = () => {
    searchForm.resetFields()
    prodCongfectionsHistory(null)
  }

  const columns = [
    {
      title: "Produit",
      dataIndex: ["produit", "libelle"],
    },
    {
      title: "Quantite",
      dataIndex: "quantite",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => {
        const formattedDate = dayjs(record.date).format("YYYY-MM-DD: HH:mm");
        return <span>{formattedDate}</span>;
      },
    },
  ];

  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Card title={"Analyse"}>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Filter" key="1">
            <Form
              layout="vertical"
              form={searchForm}
              onFinish={prodCongfectionsHistory}
            >
              <Row className="d-flex flex-row justify-content-evenly">
                <Col span={12}>
                  <Form.Item
                    name={"produitId"}
                    label="product"
                  >
                    <Select size="large">
                      {produits.map((cl, index) => (
                        <Select.Option key={index} value={cl.id}>
                          {cl.libelle}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ marginRight: "30px" }}>
                  <Form.Item
                    name={"date"}
                    label="date"
                    valuePropName="date"
                    rules={[
                      {
                        required: true,
                        message: "selectionner date range!",
                      },
                    ]}
                  >
                    <RangePicker 
                      size="large"
                      className="w-100"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Col className="px-2">
                  <Form.Item className="m-0">
                    <Button type="primary" htmlType="submit">
                      Valider
                    </Button>
                  </Form.Item>
                </Col>
                <Col className="px-2">
                  <Button onClick={cancelSearch} type="danger">
                    Annuler
                  </Button>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>

        <Table
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          locale={locale}
          columns={columns}
          dataSource={dataTable}
          pagination={{ position: ["bottomCenter"], pageSize: 50 }}
        />
      </Card>
    </Spin>
  );
};

export default AnalyseStockConfection;
