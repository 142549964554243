import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  DatePicker,
  Dropdown,
  Menu,
} from "antd";
import { Collapse } from "antd";
import StockConfection from "../../../uiHelpers/production/confection/StockConfection";
import AnalyseStockConfection from "../../../uiHelpers/production/confection/AnalyseStockConfection";
const { Panel } = Collapse;
const { TabPane } = Tabs;

const ConfectionsV2 = () => {
  const [isLoading, setLoading] = useState(false);

  return (
    <>
      <Spin spinning={isLoading} size="large">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Confection" key="1">
            <StockConfection />
          </TabPane>
          <TabPane tab="Analyse" key="2">
            <AnalyseStockConfection />
          </TabPane>
        </Tabs>
      </Spin>
    </>
  );
};

export default ConfectionsV2;
