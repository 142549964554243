import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "./assets/App.css";
import "./assets/purple-antd.css";
import BonCommande from "./componets/pages/achat/BonCommande";
import BonReception from "./componets/pages/achat/BonReception";
import DashboardBonCommande from "./componets/pages/achat/DashboardBonCommande";
import DashboardBonReception from "./componets/pages/achat/DashboardBonReception";
import DashboardFacture from "./componets/pages/achat/DashboardFacture";
import Facture from "./componets/pages/achat/Facture";
import Assemblage from "./componets/pages/production/assemblage/Assemblage";
import StockAssemblage from "./componets/pages/production/assemblage/StockAssemblage";
import Broderie from "./componets/pages/production/broderie/Broderie";
import StockBroderie from "./componets/pages/production/broderie/StockBroderie";
import Confection from "./componets/pages/production/confection/confection";
import BonLivraisonSimple from "./componets/pages/direct/BonLivraisonSimple";
import DashboardBonLivraisonSimple from "./componets/pages/direct/DashboardBonLivraisonSimple";
import DashboardFactureSimple from "./componets/pages/direct/DashboardFactureSimple";
import DashboardTicketCaisse from "./componets/pages/direct/DashboardTicketCaisse";
import FactureSimple from "./componets/pages/direct/FactureSimple";
import TicketCaisse from "./componets/pages/direct/TicketCaisse";
import Client from "./componets/pages/General/Client";
import Employe from "./componets/pages/General/Employe";
import Fournisseur from "./componets/pages/General/Fournisseur";
import Produit from "./componets/pages/General/produit";
import Projet from "./componets/pages/General/Projet";
import Stock from "./componets/pages/General/Stock";
import Home from "./componets/pages/Home";
import Inscription from "./componets/pages/Inscription";
import Login from "./componets/pages/Login";
import NotFound from "./componets/pages/NotFound";
import OrdreCoupe from "./componets/pages/production/ordreCoupe/OrdreCoupe";
import StockCoupe from "./componets/pages/production/ordreCoupe/StockCoupe";
import Parametres from "./componets/pages/parametres/Parametres";
import Categorie from "./componets/pages/parametres/tabs/Categorie";
import BonRetour from "./componets/pages/stock/BonRetour";
import BonTransfert from "./componets/pages/stock/BonTransfer";
import DashboardBonRetour from "./componets/pages/stock/DashboardBonRetour";
import DashboardBonTransfert from "./componets/pages/stock/DashboardBonTransfert";
import DashboardInventaire from "./componets/pages/stock/DashboardInventaire";
import Inventaire from "./componets/pages/stock/Inventaire";
import UsersRoles from "./componets/pages/user/UsersRoles";
import Accompte from "./componets/pages/vente/Accompte";
import BonCommandeVente from "./componets/pages/vente/BonCommandeVente";
import BonLivraison from "./componets/pages/vente/BonLivraison";
import DashboradBonCommandeVente from "./componets/pages/vente/DashboardBonCommandeVente";
import DashboardDevis from "./componets/pages/vente/DashboardDevis";
import DashboardFactureVente from "./componets/pages/vente/DashboardFactureVente";
import DashboardBonLivraison from "./componets/pages/vente/DashboardLivraison";
import Devis from "./componets/pages/vente/Devis";
import FactureVente from "./componets/pages/vente/FactureVente";
import AppFooter from "./componets/uiHelpers/AppFooter";
import SideNavigation from "./componets/uiHelpers/SideNavigation";
import ThemeSelector from "./componets/uiHelpers/ThemeSelector";
import TopNavigation from "./componets/uiHelpers/TopNavigation";
import { environment } from "./env/config.env";
import { history } from "./helpers/history";
import checkAuth from "./services/check-auth.service";
import StockConfection from "./componets/pages/production/confection/StockConfection";
import MouvementStock from "./componets/pages/stock/mouvementStock";

import "antd/dist/antd.min.css";
import DashboardFianceClient from "./componets/pages/financiere/DashboardFianceClient";
import DashboardFianceFournisseur from "./componets/pages/financiere/DashboardFianceFournisseur";
import DashboardBonLivraisonEnLigne from "./componets/pages/direct/DashboardBonLivraisonEnLigne";
import BonLivraisonEnLigne from "./componets/pages/direct/BonLivraisonEnLigne";
import Production from "./componets/pages/stock/Production";
import DashboardFianceVenteEnLigne from "./componets/pages/financiere/DashboardFianceVenteEnLigne";
import ConfectionsV2 from "./componets/pages/production/confection/confectionsV2";

const { Content, Sider } = Layout;
const API_URL = environment.api;

function App() {
  
  const [isConnected, setConnection] = useState(true);

  useEffect(() => {
    checkStorage();
  }, []);

  const checkStorage = () => {
    if (localStorage.getItem("user")) {
      const token = { token: localStorage.getItem("user") };
      setConnection(true);
      checkValidity(token);
    } else {
      setConnection(false);
      history.push("/login");
    }
  };

  const checkValidity = async (token) => {
    const response = await checkAuth.checkToken(token);
    if (response.status === 200) checkVerifResult(response.data);
    else message.error("Une erreur est survenu ! ");
  };

  const setUserData = (user) => {
    const permissions = [];
    for (const permission of user.USER.auth_role.auth_role_module_permissions) {
      const perms =
        permission.auth_module_permission.authModuleId +
        "_" +
        permission.auth_module_permission.authPermissionId;
      permissions.push({ perms, etat: permission.etat });
    }
    localStorage.setItem("user", user.token);
    localStorage.setItem("permissions", JSON.stringify(permissions));
    setConnection(true);
    setTimeout(() => { window.location.reload(false);}, 200);
  };

  const checkVerifResult = (result) => {
    if (!result) {
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");
      setConnection(false);
      history.push("/login");
    }
  };

  return (
    <ThemeSelector>
      <Router history={history}>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js"> */}
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js">
          <Switch>
            {isConnected ? (
              <>
                <Layout style={{ minHeight: "100vh" }} hasSider>
                  <Sider collapsible>
                    <SideNavigation></SideNavigation>
                  </Sider>
                  <Layout className="">
                    <TopNavigation></TopNavigation>
                    <Content style={{ margin: "0 16px" }}>
                      <div className="" style={{ padding: 24, minHeight: 360 }}>
                        <Switch>
                          {/*  General Routes  */}
                          <Route path="/" exact component={Home} />
                          <Route path="/client" component={Client} />
                          <Route path="/fournisseur" component={Fournisseur} />
                          <Route path="/categorie" component={Categorie} />
                          <Route path="/parametres" component={Parametres} />
                          <Route path="/employe" component={Employe} />
                          <Route path="/produit" component={Produit} />
                          <Route path="/stock" component={Stock} />
                          <Route path="/projet" component={Projet} />

                          {/*  ACHAT  */}
                          <Route path="/bonCommande" component={BonCommande} />
                          <Route path="/bon_Commandes" component={DashboardBonCommande}/>
                          <Route path="/bonReception" component={BonReception} />
                          <Route path="/bon_Receptions" component={DashboardBonReception}/>
                          <Route path="/factureAchat" component={Facture} />
                          <Route path="/facture_achat" component={DashboardFacture} />

                          {/*  VENTE  */}
                          <Route path="/devis_list" component={DashboardDevis} />
                          <Route path="/devis" component={Devis} />
                          <Route path="/accompte" component={Accompte} />
                          <Route path="/v/bon_commandes"component={DashboradBonCommandeVente} />
                          <Route path="/v/bonCommande" component={BonCommandeVente} />
                          <Route path="/v/bon_livraisons" component={DashboardBonLivraison} />
                          <Route path="/v/bonLivraison" component={BonLivraison} />
                          <Route path="/factureVente" component={FactureVente} />
                          <Route path="/facture_vente" component={DashboardFactureVente} />

                          {/*  Direct  */}
                          <Route path="/ticketCaisse" component={TicketCaisse} />
                          <Route path="/tickets_caisse" component={DashboardTicketCaisse} />
                          <Route path="/s/bon_livraisons" component={DashboardBonLivraisonSimple} />
                          <Route path="/s/bonLivraison" component={BonLivraisonSimple} />

                          <Route path="/el/bon_livraisons" component={DashboardBonLivraisonEnLigne} />
                          <Route path="/el/bonLivraison" component={BonLivraisonEnLigne} />

                          <Route path="/s/factures" component={DashboardFactureSimple} />
                          <Route path="/s/facture" component={FactureSimple}/>

                          {/* Stock */}
                          <Route path="/bonTransfert" component={BonTransfert} />
                          <Route path="/bon_transfert" component={DashboardBonTransfert} />

                          <Route path="/inventaire" component={Inventaire} />
                          <Route path="/list/inventaires" component={DashboardInventaire}/>

                          <Route path="/bonRetour" component={BonRetour} />
                          <Route path="/bon_retour" component={DashboardBonRetour} />

                          <Route path="/users" component={UsersRoles} />

                          <Route path="/order_coupe" component={OrdreCoupe} />
                          <Route path="/stock_coupe" component={StockCoupe} />

                          <Route path="/broderie" component={Broderie} />
                          <Route path="/stock_broderie" component={StockBroderie} />


                          <Route path="/assemblage" component={Assemblage} />
                          <Route path="/stock_assemblage" component={StockAssemblage} />

                          <Route path="/confection" component={Confection} />
                          <Route path="/confectionV2" component={ConfectionsV2} />
                          <Route path="/stock_produits" component={StockConfection} />
                          <Route path="/mouvement_stock" component={MouvementStock} />
                          <Route path="/production" component={Production} />
                        

                          {/* gestion finanicer */}  
                          <Route path="/finance_client" component={DashboardFianceClient} />
                          <Route path="/finance_fournisseur" component={DashboardFianceFournisseur} />
                          <Route path="/finance_vente_en_ligne" component={DashboardFianceVenteEnLigne} />
                          
                        </Switch>
                      </div>
                    </Content>
                    <AppFooter></AppFooter>
                  </Layout>
                </Layout>
              </>
            ) : (
              <>
                <Route path="/inscription" component={Inscription} />
                <Route path="/login">
                  <Login onLoginSuccess={(user) => setUserData(user)} />
                </Route>
              </>
            )}
            <Route path="/404" component={NotFound} />
            <Redirect from="*" to="/404" />
          </Switch>
        </Worker>
      </Router>
    </ThemeSelector>
  );
}
export default App;
