import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  ToolOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  DatePicker,
  Dropdown,
  Menu,
} from "antd";
import typeConfection from "../../../../helpers/typeBroderie";
import ConfectionService from "../../../../services/confection.service";

import assemblageService from "../../../../services/assemblage.service";

import typeCoupeService from "../../../../services/typeCoupe.service";
import typeTissusService from "../../../../services/typeTissus.service";

import clientService from "../../../../services/client.service";
import fournisseurService from "../../../../services/fournisseur.service";
import produitService from "../../../../services/produit.service";
import referenceBroderieService from "../../../../services/referenceBroderie.service";
import { Collapse } from "antd";

import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

const StockConfection = () => {
  const [confectionForm] = Form.useForm();
  const [confectionProdForm] = Form.useForm();
  const [confectionModal, setConfectionModal] = useState(false);
  const [validateConfectionModal, setValidateConfectionModal] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [fulldataTable, setFulldataTable] = useState([]);
  const [expDataTable, setExpDataTable] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [typeCoupe, setTypeCoupe] = useState([]);
  const [typeTissu, setTypeTissu] = useState([]);

  const [selectedType, setSelectedType] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [referencesBroderies, setReferencesBroderie] = useState([]);
  const [produits, setProduits] = useState([]);
  const [clients, setClients] = useState([]);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [maxNewQtyProduit, setMaxNewQtyProduit] = useState(null);

  useEffect(() => {
    loadConfections();
    loadTypeCoupes();
    loadTypeTissus();
    loadProduits();
    loadReferencesBrodrie();
    loadFournisseurs();
    loadClients();

    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };

  const loadConfections = async () => {
    setLoading(true);
    const response = await ConfectionService.getConfections();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadTypeCoupes = async () => {
    setLoading(true);
    const response = await typeCoupeService.getTypeCoupes();
    if (response.status === 200 || 204) setTypeCoupe(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadTypeTissus = async () => {
    setLoading(true);
    const response = await typeTissusService.getTypeTissus();
    if (response.status === 200 || 204) setTypeTissu(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadReferencesBrodrie = async () => {
    setLoading(true);
    const response = await referenceBroderieService.getReferenceBroderies();
    if (response.status === 200 || 204) setReferencesBroderie(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const loadFournisseurs = async () => {
    setLoading(true);
    const response = await fournisseurService.getFournisseurs();
    if (response.status === 200 || 204) setFournisseurs(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadClients = async () => {
    setLoading(true);
    const response = await clientService.getClients();
    if (response.status === 200 || 204) setClients(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadProduits = async () => {
    setLoading(true);
    const response = await produitService.getProduitFini();
    if (response.status === 200 || 204) setProduits(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const initTableData = (data) => {
    for (const element of data) {
      element.showType = element.type === 1 ? "Suite Bon Commande" : "Interne";
      element.bonCommande = element?.bon_commande_vente?.reference ?? "N/A";
      element.showProduit = element?.produit?.libelle;
      element.createdAt = moment(element.createdAt).format("YYYY-MM-DD");
    }
    var temp = sortByCreatedAt(data);
    setFulldataTable(temp);
    console.log("temp", temp);
    const uniqueObjects = temp.filter(
      (item, index, self) =>
        index === self.findIndex((obj) => obj.showProduit === item.showProduit)
    );

    for (let element of uniqueObjects) {
      let filterList = temp.filter(
        (it) => it.produitId === element.produitId && it.etat == 2
      );
      element.qtyObjectifTotale = filterList.reduce(
        (a, b) => a + b.nombreObjectif,
        0
      );
      element.qtyProduiteTotale = filterList.reduce(
        (a, b) => a + b.qteTotal,
        0
      );
    }
    setDataTable(uniqueObjects);
  };

  const onTableRowExpand = async (expanded, record) => {
    setLoading(true);
    var keys = [];
    if (expanded) {
      keys.push(record.id);
    }

    formatExpData(record);

    setExpandedRowKeys(keys);
    setLoading(false);
  };

  const formatExpData = (record) => {
    const data = [...fulldataTable];
    const filteredDataByProduitId = data.filter(
      (e) => e.produitId === record.produitId
    );
    for (const element of filteredDataByProduitId) {
      element.showType = element.type === 1 ? "Suite Bon Commande" : "Interne";
      element.bonCommande = element?.bon_commande_vente?.reference ?? "N/A";
      element.showProduit = element?.produit?.libelle;
      element.createdAt = moment(element.createdAt).format("YYYY-MM-DD");
    }
    var temp = sortByCreatedAt(filteredDataByProduitId);
    setExpDataTable(temp);
  };

  const changeOrdreConfectionStatus = async (record, etat) => {
    setLoading(true);
    await ConfectionService.updateOdreConfectionStatus(record.id, { etat });
    closingModal();

    setLoading(false);
  };

  const updateMode = (record) => {
    setUpdateId(record.id);
    setEditMode(true);
    confectionForm.setFieldsValue(record);
    loadStockQte(record);
    setConfectionModal(true);
  };

  const loadStockQte = async (config) => {
    setLoading(true);
    // const config = confectionForm.getFieldsValue();

    if (
      config.prodTypeCoupeId !== undefined &&
      config.prodTypeTissuId !== undefined
    ) {
      const response = await assemblageService.loadStockQte({
        prodTypeCoupeId: config.prodTypeCoupeId,
        prodTypeTissuId: config.prodTypeTissuId,
        prodReferenceBroderieId: config.prodReferenceBroderieId,
        produitId: config.produitId,
      });
      const qteStock = response?.data?.quantite ?? 0;
      confectionForm.setFieldsValue({ qteStock });
      confectionProdForm.setFieldsValue({ qteStock });
    }
    setLoading(false);
  };

  const addConfection = async (values) => {
    const form = confectionForm.getFieldsValue();
    setLoading(true);
    values.produitId = selectedProduct;

    values.etat = 0;
    if (values?.qteTotal) values.etat = 2;
    if (values?.qteTotal >= values?.nombreObjectif) values.etat = 1;

    values.qteProduite = 0; // for now but to check
    console.log("valuesssssss", values);
    const response = editMode
      ? await ConfectionService.updateConfection(updateId, values)
      : await ConfectionService.addConfection(values);
    // let response;
    // if (editMode) {
    //   values.qteProduite = 0; // for now but to check
    //   response = await ConfectionService.updateConfection(updateId, values);
    // } else {
    //   response = await ConfectionService.addConfection(values);
    // }

    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Ordre de confection ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    await closingModal();
  };

  const addGlobalConfection = async (values) => {
    setLoading(true);
    values.produitId = selectedProduct;

    const response = await ConfectionService.addGlobalConfection(values);

    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Ordre de confection ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    await closingModal();
  };

  const handleCancel = () => {
    setConfectionModal(false);
    setValidateConfectionModal(false);
    clearData();
  };

  const closingModal = async () => {
    setExpandedRowKeys([]);
    await loadConfections();
    setConfectionModal(false);
    setValidateConfectionModal(false);
    clearData();
  };

  const clearData = () => {
    confectionForm.resetFields();
    confectionForm.setFieldsValue({ type: false });
    confectionProdForm.resetFields();
    confectionProdForm.setFieldsValue({ type: false });
    setEditMode(false);
    setUpdateId(null);
    setSelectedType(false);
    setLoading(false);
    setMaxNewQtyProduit(null);
  };

  const showCommandeField = () => {
    const config = confectionForm.getFieldsValue();
    setSelectedType(config.type);
  };

  const showConfectionModal = (record) => {
    setSelectedProduct(record.produitId);
    confectionForm.setFieldsValue({ ...record, nombreObjectif: 0 });
    loadStockQte(record);
    setConfectionModal(true);
  };

  const showProdModal = (record) => {
    console.log(record)
    setMaxNewQtyProduit(record.qtyObjectifTotale - record.qtyProduiteTotale)
    setSelectedProduct(record.produitId);
    confectionForm.setFieldsValue(record);
    confectionForm.setFieldsValue({ oldTotal: record.qteTotal });
    confectionProdForm.setFieldsValue(record);
    confectionProdForm.setFieldsValue({ oldTotal: record.qtyProduiteTotale });
    setValidateConfectionModal(true);
  };

  const quantiteProdChanged = () => {
    const config = confectionForm.getFieldsValue();
    confectionForm.setFieldsValue({
      qteTotal: config.oldTotal + config.qteProduite,
    });
    const configProd = confectionProdForm.getFieldsValue();
    confectionProdForm.setFieldsValue({
      qteTotal: config.oldTotal + config.qteProduite,
      qtyProduiteTotale: configProd.oldTotal + configProd.qteProduite,
    });
    caculatePremierChoix();
    caculateDeuiemeChoix();
  };

  const caculateDeuiemeChoix = () => {
    const form = confectionForm.getFieldsValue();
    //const quantiteDeuxiemeChoix = form.qteTotal - form.quantitePremierChoix;
    const quantiteDeuxiemeChoix = form.qteProduite
      ? form.qteProduite - form.quantitePremierChoix
      : 0;
    confectionForm.setFieldsValue({ quantiteDeuxiemeChoix });
    confectionProdForm.setFieldsValue({ quantiteDeuxiemeChoix });
  };

  const caculatePremierChoix = () => {
    const form = confectionForm.getFieldsValue();
    //const quantitePremierChoix = form.qteTotal - form.quantiteDeuxiemeChoix;
    const quantitePremierChoix = form.qteProduite
      ? form.qteProduite - form.quantiteDeuxiemeChoix
      : 0;
    confectionForm.setFieldsValue({ quantitePremierChoix });
    confectionProdForm.setFieldsValue({ quantitePremierChoix });
  };

  const getMaxRetour = () => {
    return (
      confectionForm.getFieldValue("qtyObjectifTotale") -
      confectionForm.getFieldValue("qtyProduiteTotale")
    );
  };

  const getMaxRealise = () => {
    return confectionForm.getFieldValue("qtyObjectifTotale");
  };

  const getMaxNewRealise = () => {
    return (
      confectionForm.getFieldValue("qtyObjectifTotale") -
      confectionForm.getFieldValue("qtyProduiteTotale")
    );
  };

  const colums = [
    {
      title: "Produit",
      dataIndex: "showProduit",
      width: "80%",
      sorter: (a, b) => a.showProduit.localeCompare(b.showProduit),
    },
    {
      title: "Qty Objectif Totale",
      dataIndex: "qtyObjectifTotale",
      width: "30%",
      sorter: (a, b) => a.qtyObjectifTotale.localeCompare(b.qtyObjectifTotale),
    },
    {
      title: "Qty Produite Totale",
      dataIndex: "qtyProduiteTotale",
      width: "80%",
      sorter: (a, b) => a.qtyProduiteTotale.localeCompare(b.qtyProduiteTotale),
    },
    {
      title: "Actions",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Production et Controle Qualité">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              //   disabled={record.etat !== 2}
              onClick={() => showProdModal(record)}
              icon={<ToolOutlined />}
            />
          </Tooltip>
          <Tooltip title="Ajouter un ordre de Confection">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => showConfectionModal(record)}
              icon={<PlusCircleOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const expColums = [
    {
      title: "Numéro",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Type Ordre de Confection",
      dataIndex: "showType",
      sorter: (a, b) => a.showType.localeCompare(b.showType),
    },
    {
      title: "Produit",
      dataIndex: "showProduit",
      sorter: (a, b) => a.showProduit.localeCompare(b.showProduit),
    },
    {
      title: "Quantité objectif",
      dataIndex: "nombreObjectif",
      sorter: (a, b) => a.nombreObjectif.localeCompare(b.nombreObjectif),
    },
    {
      title: "Quantité Produite",
      dataIndex: "qteTotal",
      sorter: (a, b) => a.qteTotal.localeCompare(b.qteTotal),
    },
    {
      title: "Quantité Retour",
      dataIndex: "qteRetourConfection",
      sorter: (a, b) =>
        a.qteRetourConfection.localeCompare(b.qteRetourConfection),
    },
    {
      title: "Date de Creation",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      width: "20%",
      render: (text, record) => (
        <>
          {record.etat === 0 && (
            <>
              <Dropdown.Button
                overlay={
                  <Menu>
                    <Menu.Item
                      className="px-5"
                      key="1"
                      onClick={() => changeOrdreConfectionStatus(record, 2)}
                    >
                      Activer
                    </Menu.Item>
                    <Menu.Item
                      className="px-5"
                      key="4"
                      onClick={() => changeOrdreConfectionStatus(record, -1)}
                    >
                      Annuler
                    </Menu.Item>
                  </Menu>
                }
              >
                {" "}
                Confection en Attente{" "}
              </Dropdown.Button>
            </>
          )}
          {record.etat === 1 && <Tag color="#2db7f5">Validé</Tag>}
          {record.etat === 2 && (
            <Tag color="#87d068">Production & QC En cours</Tag>
          )}
          {record.etat === -1 && <Tag color="#f50">Annulé</Tag>}
        </>
      ),
      sorter: (a, b) => a.etat - b.etat,
    },
    {
      title: "Actions",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          {/* <Tooltip title="Production et Controle Qualité">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={record.etat !== 2}
              onClick={() => showProdModal(record)}
              icon={<ToolOutlined />}
            />
          </Tooltip> */}
          {record.etat === 0 && (
            <Tooltip title="Mettre à jour">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };

  const sortByCreatedAt = (data) => {
    return data
      .sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      })
      .reverse();
  };

  return (
    <Card title={"Stock Confection"}>
      <Spin spinning={isLoading} size="large">
        <br />
        <Table
          columns={colums}
          dataSource={dataTable}
          pagination={{ position: ["bottomCenter"], pageSize: 50 }}
          scroll={{ y: 500 }}
          rowKey={(record) => record.id}
          onExpand={onTableRowExpand}
          expandedRowKeys={expandedRowKeys}
          expandable={{
            rowExpandable: (record) => record.id !== null,
            expandedRowRender: (record) => (
              <>
                <Spin spinning={isLoading} size="large">
                  <Table
                    rowKey={record.id}
                    locale={locale}
                    columns={expColums}
                    dataSource={expDataTable}
                    pagination={{ position: ["none"], pageSize: 50 }}
                  />
                </Spin>
              </>
            ),
          }}
        />
      </Spin>

      <Form
        layout="vertical"
        form={confectionForm}
        onFinish={addConfection}
        disabled={isLoading}
        initialValues={{ type: 3 }}
      >
        <Modal
          destroyOnClose={true}
          visible={confectionModal}
          onOk={confectionForm.submit}
          onCancel={handleCancel}
          getContainer={false}
          okText="Enregistrer"
          cancelText="Annuler"
          centered
          width={1200}
          confirmLoading={isLoading}
          title={
            editMode
              ? "Modifier l'ordre de confection"
              : "Ajouter Un ordre de confection"
          }
        >
          <Row>
            <Col span={8} className="px-2">
              <Form.Item
                label="Type d'ordre de confection"
                name="type"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                  },
                ]}
              >
                <Select
                  size="large"
                  allowClear
                  showSearch
                  onChange={() => showCommandeField()}
                  dropdownStyle={{ position: "fixed" }}
                  placeholder="Type d'ordre de confection"
                  filterOption={(inputValue, option) => {
                    const value =
                      option.props.value?.toString().toLowerCase() || ""; // Ensure value is a string
                    const children =
                      option.props.children?.toString().toLowerCase() || ""; // Ensure children is a string
                    return (
                      value.includes(inputValue.toLowerCase()) ||
                      children.includes(inputValue.toLowerCase())
                    );
                  }}
                >
                  {typeConfection?.map((typeConfection) => (
                    <Select.Option
                      key={typeConfection.id || `key-${Math.random()}`}
                      value={typeConfection.id || null} // Assign value only if typeConfection.id exists
                    >
                      {typeConfection.type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} className="px-2">
              <Form.Item
                label="Produit"
                name="produitId"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                  },
                ]}
              >
                <Select
                  size="large"
                  allowClear
                  showSearch
                  dropdownStyle={{ position: "fixed" }}
                  placeholder="Produit"
                  disabled
                >
                  {produits?.map((produit) => (
                    <Select.Option
                      key={produit.id || `key-${Math.random()}`}
                      value={produit.id || null} // Assign value only if produit.id exists
                    >
                      {produit.libelle}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {selectedType === 1 && (
              <Col span={8} className="px-2">
                <Form.Item
                  label="Client"
                  name="clientId"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    allowClear
                    showSearch
                    placeholder="Client"
                    filterOption={(inputValue, option) => {
                      const value =
                        option.props.value?.toString().toLowerCase() || ""; // Ensure value is a string
                      const children =
                        option.props.children?.toString().toLowerCase() || ""; // Ensure children is a string
                      return (
                        value.includes(inputValue.toLowerCase()) ||
                        children.includes(inputValue.toLowerCase())
                      );
                    }}
                  >
                    {clients?.map((client) => (
                      <Select.Option
                        key={client.id || `key-${Math.random()}`}
                        value={client.id || null} // Assign value only if client.id exists
                      >
                        {client.nom} {client.prenom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {selectedType === 2 && (
              <Col span={8} className="px-2">
                <Form.Item
                  label="Fournisseur"
                  name="fournisseurId"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    allowClear
                    showSearch
                    placeholder="Fournisseur"
                    filterOption={(inputValue, option) => {
                      const value =
                        option.props.value?.toString().toLowerCase() || ""; // Ensure value is a string
                      const children =
                        option.props.children?.toString().toLowerCase() || ""; // Ensure children is a string
                      return (
                        value.includes(inputValue.toLowerCase()) ||
                        children.includes(inputValue.toLowerCase())
                      );
                    }}
                  >
                    {fournisseurs?.map((fournisseur) => (
                      <Select.Option
                        key={fournisseur.id || `key-${Math.random()}`}
                        value={fournisseur.id || null} // Assign value only if fournisseur.id exists
                      >
                        {fournisseur.nom} {fournisseur.prenom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={6} className="px-2">
              <Form.Item
                label="Type de Tissus"
                name="prodTypeTissuId"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <Select size="large" disabled>
                  {typeTissu &&
                    typeTissu.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.nom}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} className="px-2">
              <Form.Item
                label="Type de Coupe"
                name="prodTypeCoupeId"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <Select size="large" disabled>
                  {typeCoupe &&
                    typeCoupe.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.nom}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} className="px-2">
              <Form.Item
                label="Référence Broderie"
                name="prodReferenceBroderieId"
              >
                <Select size="large" disabled={true}>
                  {referencesBroderies.map((m) => (
                    <Select.Option key={m.id} value={m.id}>
                      {m.nom}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} className="px-2">
              <Form.Item
                label="Qte Stock "
                name="qteStock"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire !",
                  },
                ]}
              >
                <Input size="large" type="text" disabled />
              </Form.Item>
            </Col>
            <Col span={3} className="px-2">
              <Form.Item
                label="Qte Objectif"
                name="nombreObjectif"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire !",
                  },
                ]}
              >
                <Input
                  size="large"
                  type="text"
                  max={confectionForm.getFieldValue("qteStock")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>

      <Form
        layout="vertical"
        form={confectionProdForm}
        onFinish={addGlobalConfection}
        disabled={isLoading}
      >
        <Modal
          destroyOnClose={true}
          visible={validateConfectionModal}
          onOk={confectionProdForm.submit}
          onCancel={handleCancel}
          getContainer={false}
          okText="Enregistrer"
          cancelText="Annuler"
          centered
          width={1200}
          confirmLoading={isLoading}
          title={
            editMode
              ? "Modifier l'ordre de confection"
              : "Ajouter Un ordre de confection"
          }
        >
          <Row>
            <Col span={6} className="px-2">
              <Form.Item
                label="Qte Objectif Totale"
                name="qtyObjectifTotale"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire !",
                  },
                ]}
              >
                <Input
                  disabled={true}
                  size="large"
                  type="text"
                  max={confectionProdForm.getFieldValue("qteStock")}
                />
              </Form.Item>
            </Col>
            <Col span={6} className="px-2">
              <Form.Item
                label="Quantité Totale Réaliser"
                name="qtyProduiteTotale"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <InputNumber
                  disabled
                  size="large"
                  style={{ width: "100%" }}
                  max={() => getMaxRealise()}
                />
              </Form.Item>
              <Form.Item
                style={{
                  visibility: "hidden",
                  width: "0px",
                  height: "0px",
                  margin: "0px",
                  padding: "0px",
                }}
                name="oldTotal"
              >
                <InputNumber size="large" style={{ width: "100%" }} min={0} />
              </Form.Item>
            </Col>
            <Col span={6} className="px-2">
              <Form.Item
                label="Nouvelle Quantité Produite"
                name="qteProduite"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
                // max={() => getMaxNewRealise()}
              >
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  onChange={() => quantiteProdChanged()}
                  max={maxNewQtyProduit}
                />
              </Form.Item>
            </Col>
            <Col span={6} className="px-2">
              <Form.Item
                label="Quantité Retour confection"
                name="qteRetourConfection"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  max={() => getMaxRetour()}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="px-2">
              <Form.Item
                label="Quantité Premier Choix"
                name="quantitePremierChoix"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <InputNumber
                  min={0}
                  size="large"
                  style={{ width: "100%" }}
                  onChange={() => caculateDeuiemeChoix()}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="px-2">
              <Form.Item
                label="Quantité Deuxieme Choix"
                name="quantiteDeuxiemeChoix"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <InputNumber
                  min={0}
                  size="large"
                  style={{ width: "100%" }}
                  onChange={() => caculatePremierChoix()}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </Card>
  );
};

export default StockConfection;
